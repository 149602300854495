import React from 'react'
import { graphql , Link} from 'gatsby'
import Layout from '../components/layout'

const notFoundPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  return (
  <Layout title={data.site.siteMetadata.title}>
	  <h3>ページが見つかりませんでした。</h3>
	  <p>記事リスト</p>
      {edges.map(({ node }) => (
        <div key={node.id}>
		  	<h3>{node.frontmatter.title}</h3>
          <p>{node.frontmatter.date}</p>
          	<p>{node.excerpt}</p>
		  <Link to={node.frontmatter.path}>
			  <p>続きを読む</p>
		  </Link>
        </div>
    ))}
  </Layout>
)}

export const query = graphql`
  query {
	site {
   	  siteMetadata {
        title
 	 }
    }
    allMarkdownRemark {
      edges {
        node {
          id
          excerpt(truncate: true, pruneLength: 100)
          frontmatter {
            title
            date
			path
          }
        }
      }
    }
  }
`
export default notFoundPage;